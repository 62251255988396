@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

}

.navbar-brand1 {
  float: left;
  height: 50;
  padding: 0px 10px;
}

@media screen and (min-width: 480px) {
  #menu a.navbar-brand {
    font-size: 24px;
    font-weight: 700;
  }
}

@media screen and (max-width: 480px) {
h2{
  font-size: 30px;
}

.intro h1 {
  font-size: 62px;
  font-weight: 700;
}

.navbar-brand1 {
  padding: 0px 0px;
}
}

.dropdown {
  display:inline-flex;
  /* font-weight:var(--ifm-dropdown-font-weight); */
  position:relative;
  vertical-align:top;
 }
 .dropdown--hoverable:hover .dropdown__menu,
 .dropdown--show .dropdown__menu {
  opacity:1;
  pointer-events:all;
  transform:translateY(-1px);
  visibility:visible
 }
 .dropdown--right .dropdown__menu {
  left:inherit;
  right:0
 }
 .dropdown--nocaret .navbar__link:after {
  content:none!important
 }
 .dropdown__menu {
  /* background-color:var(--ifm-dropdown-background-color); */
  border-radius:0.4rem;
  box-shadow:0 5px 40px rgba(0,0,0,.2);
  left:0;
  list-style:none;
  max-height:80vh;
  min-width:10rem;
  opacity:0;
  overflow-y:auto;
  padding:.5rem;
  pointer-events:none;
  position:absolute;
  top:calc(100% - 0.25 + .3rem);
  transform:translateY(-.625rem);
  transition: duration 200ms;;
  transition-property:opacity,transform,visibility;
  transition-timing-function:cubic-bezier(0.08,0.52,0.52,1);
  visibility:hidden;
  z-index:100
 }
 .menu__caret,
 .menu__link,
 .menu__list-item-collapsible {
  border-radius:.25rem;
  transition:background 200ms cubic-bezier(0.08,0.52,0.52,1)
 }
 .dropdown__link {
  border-radius:.25rem;
  /* color:var(--ifm-dropdown-link-color); */
  display:block;
  font-size:.875rem;
  margin-top:.2rem;
  padding:.25rem .5rem;
  white-space:nowrap
 }
 .dropdown__link--active,
 .dropdown__link:hover {
  /* background-color:var(--ifm-dropdown-hover-background-color); */
  /* color:var(--ifm-dropdown-link-color); */
  text-decoration:none
 }
 .dropdown__link--active,
 .dropdown__link--active:hover {
  /* --ifm-dropdown-link-color:var(--ifm-link-color) */
 }
 .dropdown>.navbar__link:after {
  border-color:currentcolor transparent;
  border-style:solid;
  border-width:.4em .4em 0;
  content:"";
  margin-left:.3em;
  position:relative;
  top:2px;
  transform:translateY(-50%)
 }
